import React, {useEffect, useState} from 'react';
import "../../../../Css/dashboard/ManageEntites/manageEntites.css"
import lupa from "../../../../images/searchLUPA.png"
import PopApDeleteEstab from "../PopAps/PopApDeleteEstab";
import {fetchEstablishments} from "../../../../WebRequest/dashboard/fetchEstablishments";
import EstablishmentInfo from "./EstablishmentInfo";
import PopUpCreateEstab from "../PopAps/PopUpCreateEstab";

function ManageEntities() {
    const [isNotFound, setIsNotFound] = useState(false);

    const [buttonPopupCreateEstab, setbuttonPopupCreateEstab] = useState(false);

    const [establishments, setEstablishments] = useState([]);

    const [selectedEstablishmentId, setSelectedEstablishmentId] = useState(null);

    const [selectedEstablishment, setSelectedEstablishment] = useState(null);

    const fetchEstablishmentDetails = async (id) => {
        try {
            const token = getCookie('token');
            const response = await fetch(`https://battime.tech/api/superadmin/establishment_card?establishment_id=${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Проблема с получением данных');
            }
            const data = await response.json();
            setSelectedEstablishment(data); // Сохраняем полученные данные в состояние
        } catch (error) {
            console.error('Ошибка:', error);
            setSelectedEstablishment(null); // Сброс при ошибке
        }
    }


    useEffect(() => {
        const loadData = async () => {
            try {
                const data = await fetchEstablishments();
                setEstablishments(data);
                console.log(data);


            } catch (error) {
                console.error('Ошибка при загрузке данных: ', error);
            }
        };

        loadData();
    }, []);

    const searchEstablishments = async (searchTerm) => {
        try {
            const token = getCookie('token');
            const response = await fetch(`https://battime.tech/api/superadmin/search_establishments?name=${searchTerm}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                throw new Error('Ошибка запроса: ' + response.statusText);
            }
            setIsNotFound(false);

            const data = await response.json();
            setEstablishments(data); // Обновляем состояние списка заведений данными из ответа
        } catch (error) {
            console.error('Ошибка при выполнении поиска:', error);
            setIsNotFound(true);
        }
    };

    function getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    const handleInputChange = async (value) => {
        if (value === '') {
            try {
                const data = await fetchEstablishments();
                setEstablishments(data);
            } catch (error) {
                console.error('Ошибка при возвращении к исходному списку:', error);
            }
        } else {
            try {
                searchEstablishments(value);
            } catch (error) {
                setIsNotFound(true);
                console.error('Ошибка при поиске:', error);
            }
        }
    }


    return (
        <div className="container">
            <div className="top_of_page">
                <h1 className="headerText">Управление заведениями</h1>
                <button className="createNewEstab" onClick={() => setbuttonPopupCreateEstab(true)}>Создать новое заведение</button>
            </div>
            <div className="containerOfInfo">
                <div className="listOfEstab">

                    <div className="search-container">
                        <div className="input-wrapper">
                            <input
                                type="text"
                                className="search-input"
                                id="search-input"
                                placeholder=""
                                onChange={(e) => handleInputChange(e.target.value)}/>
                            <label htmlFor="search-input">Search</label>
                        </div>
                        <button id="search-button" className="search-button">
                            <img src={lupa} alt="Search"/>
                        </button>
                    </div>

                    <button className="addFilterDutton">
                        Add filters+
                    </button>

                    <div className="estabCount">
                        {isNotFound ? (
                            <p>0 results</p>
                        ) : (<p>{establishments.length} results</p>
                        )}
                    </div>
                    <div className="table-container">
                        <div>
                            {isNotFound ? (
                                <div className="notFoundh2">
                                    <h2>Данных нет</h2>
                                </div>
                            ) : (
                                <table className="entities-table">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Address</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {establishments.map((entity, index) => (
                                        <tr key={index} onClick={() => setSelectedEstablishmentId(entity.id)}>
                                            <td>{entity.emoji ? entity.emoji : '🏢'}</td>
                                            <td><p className="bold-text">{entity.name}</p></td>
                                            <td>{entity.address}</td>
                                            <td>{entity.registered ? 'true' : 'false'}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>

                </div>

                <div className="mainDivInfoAboutEstab">
                    {selectedEstablishmentId && <EstablishmentInfo id={selectedEstablishmentId} />}
                <PopUpCreateEstab trigger={buttonPopupCreateEstab} setTrigger={setbuttonPopupCreateEstab} />


                    {/*
                    <div className="infoSection">
                        <h3>Description</h3>
                        <div className="centerText">
                            <p>{description}</p>
                        </div>
                    </div>
                    <div className="infoSection">
                        <div className="centerText">
                            Карта(в разработке)
                        </div>
                    </div>
                    <div className="">
                        <button className="createNewEstab buttonGroup">Перейти К Админу Заведения</button>
                        <button className="createNewEstab buttonGroup">Изменить Заведение</button>
                        <div>
                            <div className="logout">
                                <button className="buttonGroup" onClick={() => setButtonPopup(true)}>Удалить Заведения
                                </button>
                            </div>
                            <PopApDeleteEstab trigger={buttonPopup} setTrigger={setButtonPopup}>
                                <h1 className="headerText centerTextInPopAp">Удааление заведения</h1>
                                <h3 className="centerTextInPopAp ">Вы действительно хотите удалить заведение?</h3>
                                <div className="twoButtonOnPopUp">
                                    <button className="redButton" onClick={() => setButtonPopup(false)}>Нет</button>
                                    <button className="blueButton">Да</button>
                                </div>
                            </PopApDeleteEstab>
                        </div>
                    </div>*/}
                </div>
            </div>
        </div>
    )
        ;
}

export default ManageEntities;
