export const fetchEstablishments = async () => {
    try {
        const token = getCookie('token');

        const response = await fetch('https://battime.tech/api/superadmin/get_establishments', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });

        if (!response.ok) {
            throw new Error('Ошибка запроса: ' + response.statusText);
        }

        return await response.json();
    } catch (error) {
        console.error('Ошибка при получении заведений:', error);
        throw error;
    }
};

function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
