import React from "react";
import AuthSuperAdmin from "./Components/Login/AuthSuperAdmin";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import MainDasboard from "./Components/dasboard/MainDasboard";


export default function Routing() {
        return(
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigate replace to="/dashboard" />} />

                    <Route path="/login" element={<AuthSuperAdmin/>} />

                    <Route path="/dashboard" element={<MainDasboard/>} />
                </Routes>
            </BrowserRouter>
        )
}
