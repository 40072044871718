import axios from 'axios';
import { useCookies } from 'react-cookie'

const VERIFY_2FA_API_URL = 'https://battime.tech/api/superadmin/verify-2fa';



export const verify2FA = async (smsCode, setCookie, navigate) => {
    try {
        const response = await axios.post(VERIFY_2FA_API_URL, { Sms_User_Sent: smsCode });

        if (response.status === 200) {
            //console.log(response.data.access_token)
            setCookie('token', 'Bearer '+ response.data.access_token);
            setCookie(response.data);
            navigate('/dashboard');
        } else {
            console.error(response)
        }
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    throw new Error('Неверный код двухфакторной аутентификации, или код истек.');
                default:
                    throw new Error('Ошибка на стороне сервера.');
            }
        } else {
            throw new Error('Ошибка запроса.');
        }
    }
};


export const Check2facodeService = {
    verify2FA,
};
