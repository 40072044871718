import axios from 'axios';

const API_URL = "https://battime.tech/api/superadmin/login";

const authRequest = async (email, password) => {
    try {
        console.log(API_URL);
        const response = await axios.post(API_URL, { email, password });
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        if (error.response) {
            if (error.response.status === 401) {
                throw new Error('Неверная почта или пароль.');
            } else if (error.response.status === 500) {
                throw new Error('Ошибка на стороне сервера.');
            }
        } else {
            throw new Error('Ошибка запроса.');
        }
    }
};

export const AuthService = {
    authRequest,
};
