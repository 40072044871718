import React from 'react';
import './../../../../Css/dashboard/PopAps/deletePopAp.css';
import cancel from "./../../../../../src/images/cancel.png"

const PopApDeleteEstab = ({trigger, setTrigger, id}) => {
    const handleDelete = async () => {
        try {
            const token = getCookie('token');
            const response = await fetch(`https://battime.tech/api/superadmin/delete/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`, // Исправляем на шаблонную строку
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Проблема с удалением заведения');
            }
            alert('Заведение успешно удалено');
            setTrigger(false);
        } catch (error) {
            console.error('Ошибка при удалении:', error);
        }
    };
    function getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }
    return trigger ? (
        <div className="popup">
            <div className="popup-inner">
                <img className='closeImg' onClick={() => setTrigger(false)} src={cancel} alt="close"></img>
                <h1 className="headerText centerTextInPopAp">Удаление заведения</h1>
                <h3 className="centerTextInPopAp">Вы действительно хотите удалить заведение?</h3>
                <div className="twoButtonOnPopUp">
                    <button className="blueButton" onClick={() => setTrigger(false)}>Нет</button>
                    <button className="redButton" onClick={handleDelete}>Да</button>
                </div>
            </div>
        </div>
    ) : "";
};
export default PopApDeleteEstab;