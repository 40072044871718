import React, {useState} from 'react';
import cancel from "./../../../../../src/images/cancel.png"
import "./../../../../Css/dashboard/PopAps/createEstabPopUp.css"

const PopUpCreateEstab = ({trigger, setTrigger}) => {
    const [formValues, setFormValues] = useState({
        name: '',
        description: '',
        // Поле для загрузки файла аватара опускаем для упрощения
        address: '',
        address_latitude: '',
        address_longitude: '',
/*        filters: [],
        menu: [],*/
/*        contacts: {
            email: '',
            instagram: '',
            vk: '',
            telegram: '',
        },*/
        subscription_type: '',
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Данные, отправляемые на сервер:', formValues);
        try {
            const token = getCookie('token');
            const response = await fetch('https://battime.tech/api/superadmin/create_establishments', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formValues),
            });
            const responseData = await response.json();
            if (!response.ok) {
                throw new Error('Проблема с созданием заведения');
            }
            alert('Заведение успешно создано');
            setTrigger(false);
        } catch (error) {
            console.error('Ошибка при создании заведения:', error);
        }
    };

    function getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    return trigger ? (
        <div className="popup">
            <div className="popup-inner">
                <img className='closeImg' onClick={() => setTrigger(false)} src={cancel} alt="close"></img>
                <h1 className="headerText centerTextInPopAp">Создание нового заведения</h1>
                <form className="form" onSubmit={handleSubmit}>
                    <div className="input-wrapper popUpCreateEsrtab">
                        <input
                            name="name"
                            value={formValues.name}
                            onChange={handleChange}
                            className="search-input"
                            placeholder=""
                            required
                        />
                        <label htmlFor="search-input">Название заведения</label>
                    </div>

                    <div className="input-wrapper popUpCreateEsrtab">
                        <input
                            name="description"
                            value={formValues.description}
                            onChange={handleChange}
                            className="search-input"
                            placeholder=""
                            required
                        />
                        <label htmlFor="search-input">Описание</label>
                    </div>

                    <div className="input-wrapper popUpCreateEsrtab">
                        <input
                            name="address"
                            value={formValues.address}
                            onChange={handleChange}
                            className="search-input"
                            placeholder=""
                            required
                        />
                        <label htmlFor="search-input">Адрес</label>
                    </div>

                    <div className="input-wrapper popUpCreateEsrtab">
                        <input
                            name="address_latitude"
                            value={formValues.address_latitude}
                            onChange={handleChange}
                            className="search-input"
                            placeholder=""
                            required
                        />
                        <label htmlFor="search-input">Широта</label>
                    </div>

                    <div className="input-wrapper popUpCreateEsrtab">
                        <input
                            name="address_longitude"
                            value={formValues.address_longitude}
                            onChange={handleChange}
                            className="search-input"
                            placeholder=""
                            required
                        />
                        <label htmlFor="search-input">Долгота</label>
                    </div>
                    <div className="input-wrapper popUpCreateEsrtab">
                        <select
                            name="subscription_type"
                            value={formValues.subscription_type}
                            onChange={handleChange}
                            className="search-input"
                            required
                        >
                            <option value="" disabled>Выберите тип подписки</option>
                            <option value="prime">true</option>
                            <option value="false">false</option>
                        </select>
                        <label htmlFor="search-input">Тип подписки</label>
                    </div>
                    {/* Дополнительные поля формы */}
                    <button type="submit" className="blueButton">Создать</button>
                </form>
            </div>
        </div>
    ) : "";
};

export default PopUpCreateEstab;
