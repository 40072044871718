import React, {useEffect, useState} from 'react';
import PopApDeleteEstab from './../PopAps/PopApDeleteEstab'; // Путь к компоненту
import "../../../../Css/dashboard/ManageEntites/manageEntites.css"
import {getDetailsEstablishment} from "../../../../WebRequest/dashboard/getDetailsEstablishment";


const EstablishmentInfo = ({id}) => {
    const [establishment, setEstablishment] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [buttonPopup, setButtonPopup] = useState(false);

    useEffect(() => {
        const fetchEstablishmentDetails = async () => {
            setIsLoading(true);
            try {
                const data = await getDetailsEstablishment(id);
                setEstablishment(data);
            } catch (error) {
                console.error('Ошибка:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (id) {
            fetchEstablishmentDetails();
        }
    }, [id]);

    if (isLoading) {
        return (<div className="centerLoaderSpin">
                <span className="loaderSpin"></span>
            </div>);
    }

    if (!establishment) {
        return <p>Информация о заведении не найдена.</p>;
    }


    return (<div className="infoAboutEstab">
            {establishment && (<div>
                    <h2 className="headerText nameEstab">{establishment.name}</h2>
                    <div className="infoSection">
                        <h3>Часы работы</h3>
                        <div className="centerText">
                            <p>{establishment.workload_parameter}</p>
                        </div>
                    </div>

                    <div className="infoSection">
                        <h3>Отметка на карте</h3>
                        <div className="centerText">
                            <p>ожидаем</p>
                        </div>
                    </div>

                    <div className="infoSection">
                        <h3>address_latitude</h3>
                        <div className="centerText">
                            <p>{establishment.coordinates.latitude ? establishment.coordinates.latitude : "Информация отсутствует"}</p>
                        </div>
                    </div>

                    <div className="infoSection">
                        <h3>address_longitude</h3>
                        <div className="centerText">
                            <p>{establishment.coordinates.longitude ? establishment.coordinates.longitude : "Информация отсутствует"} </p>
                        </div>
                    </div>

                    <div className="infoSection">
                        <h3>Адрес</h3>
                        <div className="centerText">
                            <p>
                                {establishment.establishment_addresses ? establishment.establishment_addresses : "Информация отсутствует"}
                            </p>
                        </div>
                    </div>

                    <div className="buttonGroupDiv">
                        <button className="createNewEstab buttonGroup">Перейти К Админу Заведения</button>
                        <button className="createNewEstab buttonGroup">Изменить Заведение</button>
                        <div className="logout">
                            <button className="buttonGroup" onClick={() => setButtonPopup(true)}>Удалить Заведения
                            </button>
                        </div>
                    </div>
                </div>)}

            <PopApDeleteEstab trigger={buttonPopup} setTrigger={setButtonPopup} id={id}/>
        </div>);
};

export default EstablishmentInfo;