import React from 'react';
import {Link} from 'react-router-dom';
import "./../../../Css/dashboard/SidebarMenu.css"; // Путь к вашему CSS файлу
import outButtonImage from './../../../images/out_button.png';
import peopleText from './../../../images/people_info.png';


export const SidebarMenu = ({onMenuClick}) => {
    const userFullName = "Иванов Иван";
    const handleLogout = () => {
        console.log("Выход пользователя из системы");
    };

    return (
        <aside className="sidebar">
            <div className="sidebar-header">
                <h2 className="main-logo-text">Battime Admin</h2>
            </div>
            <nav className="nav">
                    <ul>
                        <li>
                            <button onClick={() => onMenuClick('management')}>Управление заведениями</button>
                        </li>
                        <li>
                            <button onClick={() => onMenuClick('userManagement')}>Управление пользователями</button>
                        </li>
                        <li>
                            <button onClick={() => onMenuClick('serverManagement')}>Управление сервером</button>
                        </li>
                        <li>
                            <button onClick={() => onMenuClick('logs')}>Логи</button>
                        </li>
                    </ul>
            </nav>

            <div className="user-info">
                <p><img src={peopleText} alt="User"/>{userFullName}</p>
            </div>
            <div className="logout">
                <button onClick={handleLogout}>Выйти <img src={outButtonImage} alt="Logout" width="20" height="20"/>
                </button>
            </div>
        </aside>
    );
}
