
import React, {useEffect, useState} from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
// import { check } from './FunctionDasboards/redirectToLogin';
import "../../Css/dashboard/main.css";

import "./../../Css/dashboard/main.css";
// import TimePick from "./FunctionDasboards/timePick";
import {SidebarMenu} from "./FunctionDasboards/SidebarMenu";
import ManageEntities from "./FunctionDasboards/ManageEntites/ManageEntities";

export default function MainDasboard() {
    //const [cookies] = useCookies(['token']);
    //const navigate = useNavigate();
    // useEffect(() => {
    //     check(cookies.token, navigate);
    // }, [cookies.token, navigate]);
    const [activeSection, setActiveSection] = useState('management');
    const handleMenuClick = (section) => {
        setActiveSection(section);
    };
    const UserManagement = () => <div>Управление пользователями</div>;


    const ServerManagement = () => <div>Управление сервером</div>;

    const Logs = () => <div>Логи</div>;

    let content;
    switch (activeSection) {
        case 'management':
            content = <ManageEntities />;
            break;
        case 'userManagement':
            content = <UserManagement />;
            break;
        case 'serverManagement':
            content = <ServerManagement />;
            break;
        case 'logs':
            content = <Logs />;
            break;
        default:
            content = <div>Раздел не найден</div>;
    }
    return (

            <div className="test">
                <SidebarMenu onMenuClick={handleMenuClick} />
                <div className="dashboard-content">
                    {content}
                </div>
            </div>
    );
}
