import {AuthService} from "../../WebRequest/auth/authRequest";
import {Check2facodeService} from "../../WebRequest/auth/check2FAcode";
import React, {useState, useCallback} from 'react';
import "../../Css/LoginSuperAdmin.css";
import point from './../../Img/blue_circle.png';
import {useCookies} from 'react-cookie';
import {useNavigate} from 'react-router-dom';

export default function AuthSuperAdmin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [code, setCode] = useState(''); // State for the 2FA code
    const [cookies, setCookie] = useCookies(['token']);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setLoading(true);
        try {
            const response = await AuthService.authRequest(email, password);
            console.log(response.message)
            setLoading(false);
            if (response.message === "Код двухфакторной аутентификации отправлен.") {
                setSuccess(true);
            }
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
    };

    // Handle 2FA code submission
    const handle2FASubmit = useCallback(async (event) => {
        event.preventDefault(); // Prevent the default form submission behaviour
        setError('');
        setLoading(true);
        try {
            await Check2facodeService.verify2FA(code.toString(), setCookie, navigate);
            console.log(code)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error.message);
            setSuccess(true);
            console.error(error.message);
            console.error(code);
        }
    }, [code, setCookie, navigate]);


    // Login form
    const loginForm = (
        <form onSubmit={handleSubmit} className="form_login">
            <div>
                <label htmlFor="email">Login</label>
                <input type="email" id="email" value={email} onChange={e => setEmail(e.target.value)} required/>
            </div>
            <div>
                <label htmlFor="password">Password</label>
                <input type="password" id="password" value={password} onChange={e => setPassword(e.target.value)}
                       required/>
            </div>
            <p className="signature">
                Если нет доступа пишите команде разработке
            </p>
            {error && <div className="error_text">{error}</div>}
            <div className="div-button-sabmit">
                <button className="confirm_button" type="submit" disabled={loading}>
                    {loading ? "Loading...": 'Sign in'}
                </button>
            </div>
        </form>
    );

    // 2FA code verification form
    const verify2FAForm = (
        <div>
            <div className="text_info_login">
                Введите код подтвержедения который был отправлен вам на почту
            </div>
            <form onSubmit={handle2FASubmit} className="form-2fa">
                <div>
                    <label htmlFor="code"></label>
                    <input
                        type="text"
                        id="code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        maxLength="6"
                        pattern="\d{6}"
                        required
                        className="input_code_2fa"
                    />
                    {error && <div className="error_text">{error}</div>}
                </div>

                <div className="div-button-sabmit">
                    <button className="confirm_button" type="submit" disabled={loading}>
                        {loading ? 'Loading...' : 'Sign in'}
                    </button>
                </div>

                <div className="div-button-sabmit">
                    <button className="reset_button" type="button" onClick={() => setSuccess(false)}>Вернуться к вводу
                        логина и пароля
                    </button>
                </div>
            </form>
        </div>
    );

    return (
        <div className="backfront_main">

            <text className='label-com'>
                Вход для админов Battime
            </text>
            {success ? verify2FAForm : loginForm}
            <div className="div-image">
                <img src={point} alt="Circle 1" className="cirle_1"/>
                <img src={point} alt="Circle 2" className="cirle_2"/>
            </div>
        </div>
    );
}
