export const getDetailsEstablishment = async (id) => { // Добавляем параметр id
    try {
        const token = getCookie('token');

        const response = await fetch(`https://battime.tech/api/establishment_card?establishment_id=${id}`, { // Используем шаблонную строку для вставки id
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Исправляем на шаблонную строку
                'Content-Type': 'application/json'
            },
        });

        if (!response.ok) {
            throw new Error('Ошибка запроса: ' + response.statusText);
        }

        return await response.json();
    } catch (error) {
        console.error('Ошибка при получении заведений:', error);
        throw error;
    }
};

function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
